//import { Link } from "react-router-dom";

export const Footer = () => {
    return (
        <div className='main-color purple-background'>
            <footer className='container d-flex flex-wrap justify-content-between align-items-center py-5'>
                <div className="col-6 col-lg-3">
                    <img src="https://mindfuel.ca/wp-content/uploads/2022/11/MindFuel_Logo_white_orange_TM-e1669664226651.png" width='200' height='142' alt='MindFuel Logo'></img>
                </div>
                <p className="col-6 col-lg-9 text-white">
                    The MindFuel Foundation<br/>
                    Suite 260 – 3512 33 Street NW<br/>
                    Calgary, AB Canada T2L 2A6<br/>
                    <a href="http://mindfuel.ca" target="_blank" rel="noopener noreferrer" className="text-orange">http://mindfuel.ca</a><br/>
                    Registered Charity Number:<br/>
                    132657065 RR0001
                </p>
                {/*<p className='col-md-4 mb-0 text-white'>© MindFuel</p>*/}
                {/*
                <ul className='nav navbar-dark col-md-4 justify-content-end'>
                    <li className='nav-item'>
                        <Link to='/home' className='nav-link px-2 text-white'>
                            Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/search' className='nav-link px-2 text-white'>
                            Search Activities
                        </Link>
                    </li>
                </ul>
                */}
            </footer>
        </div>
    );
}