//import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AddNewResource } from './components/AddNewResource';
import { AdminMessages } from './components/AdminMessages';
import { ChangeQuantityOfResources } from './components/ChangeQuantityOfResources';
import { useKeycloak } from '@react-keycloak/web';

export const ManageWonderplayPage = () => {

    //const { authState } = useOktaAuth();
    const { keycloak } = useKeycloak()

    const [changeQuantityOfResourcesClick, setChangeQuantityOfResourcesClick] = useState(false);
    const [messagesClick, setMessagesClick] = useState(false);

    function addResourceClickFunction(){
        setChangeQuantityOfResourcesClick(false);
        setMessagesClick(false);
    }

    function changeQuantityOfResourcesClickFunction(){
        setChangeQuantityOfResourcesClick(true);
        setMessagesClick(false);
    }

    function messagesClickFunction(){
        setChangeQuantityOfResourcesClick(false);
        setMessagesClick(true);
    }

    const isAdmin = (keycloak:any) => {
        return keycloak && 
               keycloak.tokenParsed && 
               keycloak.tokenParsed.resource_access['wonderplay-app'] && 
               keycloak.tokenParsed.resource_access['wonderplay-app'].roles.includes('wvAdmin')
               
      }

    if(!isAdmin(keycloak)){
        return <Redirect to='/home' />
    }

    return (
        <div className='container'>
            <div className='mt-5'>
                <h3>Manage App</h3>
                <nav>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <button onClick={addResourceClickFunction} className='nav-link active' id='nav-add-resource-tab' data-bs-toggle='tab' 
                            data-bs-target='#nav-add-resource' type='button' role='tab' aria-controls='nav-add-resource' 
                            aria-selected='false'
                        >
                            Add new activity
                        </button>
                        <button onClick={changeQuantityOfResourcesClickFunction} className='nav-link' id='nav-quantity-tab' data-bs-toggle='tab' 
                            data-bs-target='#nav-quantity' type='button' role='tab' aria-controls='nav-quantity' 
                            aria-selected='true'
                        >
                            Remove activity
                        </button>
                        <button onClick={messagesClickFunction} className='nav-link' id='nav-messages-tab' data-bs-toggle='tab' 
                            data-bs-target='#nav-messages' type='button' role='tab' aria-controls='nav-messages' 
                            aria-selected='false'
                        >
                            Messages
                        </button>
                    </div>
                </nav>
                <div className='tab-content' id='nav-tabContent'> 
                    <div className='tab-pane fade show active' id='nav-add-resource' role='tabpanel'
                        aria-labelledby='nav-add-resource-tab'>
                            <AddNewResource />
                    </div>
                    <div className='tab-pane fade' id='nav-quantity' role='tabpanel' aria-labelledby='nav-quantity-tab'>
                       {changeQuantityOfResourcesClick ? <ChangeQuantityOfResources />:<></>}
                    </div>
                    <div className='tab-pane fade' id='nav-messages' role='tabpanel' aria-labelledby='nav-messages-tab'>
                        {messagesClick ? <AdminMessages/>:<></>}
                    </div>
                </div>
            </div>
        </div>
    );
}