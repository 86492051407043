import { useState } from "react";
import ResourceModel from "../../models/ResourceModel"

export const OrderFormPage: React.FC<{
    resource: ResourceModel | undefined, fees: number, setInsecureName: any, insecureName: string, setInsecureEmail: any,
    insecureEmail: string, setInsecureConfirmEmail: any, insecureConfirmEmail: string, setRentPhase: any
}> = (props) => {

    const [displayError, setDisplayError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    //Form validation functions
    function validateName() {
        if (props.insecureName !== "") {
            return true;
        }
        setErrorMsg("Name cannot be empty.");
        return false;
    }

    function checkValidEmail() {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(props.insecureEmail)) {
            return true;
        }
        setErrorMsg("Invalid email address.");
        return false;
    }

    function emailMatch() {
        if (props.insecureEmail === props.insecureConfirmEmail) {
            return true;
        }
        setErrorMsg("Emails do not match.");
        return false;
    }

    async function checkInfo() {
        if (validateName() && checkValidEmail() && emailMatch()) {
            const url = `${process.env.REACT_APP_API}/resources/insecure/checkout/${props.insecureEmail}/${props.resource?.id}`;
            const requestOptions = {
                method: 'PUT',
                headers: {
                    //Authorization: `Bearer ${keycloak.token}`,
                    'Content-Type': 'application/json'
                }
            };
            const checkoutResponse = await fetch(url, requestOptions);
            if (!checkoutResponse.ok) {
                setDisplayError(true);
                setErrorMsg("Activity is already rented with same email address.");
                throw new Error('Something went wrong!:Checkout Resource');
            }
            const checkoutResponseJson = await checkoutResponse.json();
            if (checkoutResponseJson) {
                setDisplayError(false);
                props.setRentPhase(3);
            }
            else {
                setDisplayError(true);
                setErrorMsg("Activity is already rented with same email address.");
            }
        }
        else {
            setDisplayError(true);
        }
    }

    return (
        <div>
            <div className="container justify-content-center align-items-center col-8">
                {displayError &&
                    <div className='alert alert-danger mt-3' role='alert'>
                        <p>{errorMsg}</p>
                    </div>
                }
                <div className="row">
                    <div className="container justify-content-center align-items-center">
                        <fieldset className="mobile-cart-summary-form-group form-group hidden-sm hidden-md hidden-lg hidden-xl">
                            <div className="cart-summary">
                                <div className="cart-summary-header">
                                    <div className="header-inner">
                                        <h1 className="heading override-colour">Your order</h1>
                                    </div>
                                </div>
                                <div className="cart-summary-body">
                                    <table className="cart-items col-4">
                                        <thead className="hidden">
                                            <tr className="product-header-row">
                                                <th className="row-label" scope="row">Product</th>
                                                <th className="row-value" scope="row">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="product-row">
                                                <td className="row-label">
                                                    {props.resource?.title}
                                                </td>
                                                <td className="row-value">
                                                    ${props.fees}
                                                </td>
                                            </tr>
                                            <tr className="product-separator-row">
                                                {/* <td colSpan="2"><hr/></td> */}
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr className="cart-subtotal-row">
                                                <th className="row-label" scope="col">Subtotal</th>
                                                <td className="row-value">
                                                    ${props.fees}
                                                </td>
                                            </tr>
                                            <tr className="cart-total-row">
                                                <th className="row-label" scope="col">Total</th>
                                                <td className="row-value override-colour">
                                                    ${props.fees}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="buyer-order-form-group form-group">
                            <h2 className="heading override-colour">Your details</h2>
                            <div className="form-row">
                                <label htmlFor="order_buyer_name" className="hidden">Full name</label>
                                <input data-optional="false" className="form-control icon your-name" type="text" name="order[buyer_name]"
                                    id="order_buyer_name" placeholder="Your name" onChange={e => props.setInsecureName(e.target.value)} value={props.insecureName} />
                            </div>
                            <div className="form-row">
                                <label htmlFor="order_buyer_email" className="hidden">Email</label>
                                <input data-optional="false" className="form-control icon email" type="email" name="order[buyer_email]"
                                    id="order_buyer_email" placeholder="Your email" onChange={e => props.setInsecureEmail(e.target.value)} value={props.insecureEmail} />
                            </div>
                            <div className="form-row">
                                <label htmlFor="order_buyer_confirm_email" className="hidden">Confirm email</label>
                                <input data-optional="false" className="form-control icon email" type="email" name="order[buyer_confirm_email]"
                                    id="order_buyer_confirm_email" placeholder="Confirm email" onChange={e => props.setInsecureConfirmEmail(e.target.value)}
                                    value={props.insecureConfirmEmail} />
                            </div>
                        </fieldset>
                        <fieldset className="form-group">
                            <div className="label-on-right">
                                <input name="order[send_product_update_emails_consent]" type="hidden" value="0" />
                                <input type="checkbox" value="1" name="order[send_product_update_emails_consent]" id="order_send_product_update_emails_consent" />
                                <label htmlFor="order_send_product_update_emails_consent">
                                    <span className="hidden-if-gift-order">Subscribe to product update emails</span>
                                </label>
                            </div>
                        </fieldset>
                        <div className='text-center'>
                            <button onClick={() => checkInfo()} className='btn btn-lg button-orange text-white rounded-corner mt-3'>Continue To Payment</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}