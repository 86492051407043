export const ExploreTopResources = () => {
    return (
        <div className="container">
            <div className='container-fluid py-5 d-flex justify-content-center align-items-center'>
                <div>
                    <h1 className='display-5 fw-bold text-title-purple text-center'>You can now rent these games!</h1>
                    <div className='row'>
                        <p className='col-md-6 fs-4 text-basic text-justify px-4'>
                            You spoke!  We listened!<br /><br />

                            Based on valued user feedback, we’re delighted to introduce our game rentals option – WonderPlay,
                            which is ideal for users who just want access to our award-winning games for short term usage,
                            without the commitment of a subscription.<br /><br />

                            Now, with WonderPlay you can rent games for 7-days to use in class or at home.
                            WonderPlay is easy and convenient because student setup or accounts are NOT required!
                        </p>
                        <p className='col-md-6 fs-4 text-basic text-justify px-4'>
                            Teachers should share this link with parents who may want to provide better quality screen time for their children.<br /><br />

                            To access the entire collection of science and math games and more, you can subscribe to Wonderville.org
                            for as little as $5 a month.<br /><br />

                            (For best results, play these games on a computer, laptop or Chromeresource)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}