import "../../App.css";

import { useEffect, useState } from "react";
import { useKeycloak } from '@react-keycloak/web';

import ResourceModel from "../../models/ResourceModel";

import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { CreditCardWrapper } from "./CreditCardWrapper";
import { ResourceDetailsPage } from "./ResourceDetailsPage";
import { RentNowPage } from "./RentNowPage";
import { OrderFormPage } from "./OrderFormPage";
import { ThankYouPage } from "./ThankYouPage";

export const ResourceCheckoutPage:React.FC<{category: string, onCategoryChange: any}> = (props) => {

  const [resource, setResource] = useState<ResourceModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState(null);

  /*
  Reviews are currently not in use

  const [reviews, setReviews] = useState<ReviewModel[]>([])
  const [totalStars, setTotalStars] = useState(0);
  const [isLoadingReview, setIsLoadingReview] = useState(true);
  const [isReviewLeft, setIsReviewLeft] = useState(false);
  const [isLoadingUserReview, setIsLoadingUserReview] = useState(true);
  */

  //Payment
  const [displayError, setDisplayError] = useState(false);

  const resourceId = window.location.pathname.split("/")[2];
  const rentTag = (window.location.pathname.split("/").length > 3) ? window.location.pathname.split("/")[3] : null;

  const { keycloak } = useKeycloak()

  //rent phase
  const [rentPhase, setRentPhase] = useState(0);
  const [insecureName, setInsecureName] = useState('');
  const [insecureEmail, setInsecureEmail] = useState('');
  const [insecureConfirmEmail, setInsecureConfirmEmail] = useState('');
  const [insecureUserToken, setInsecureUserToken] = useState('NONE');

  useEffect(() => {
    const fetchResource = async () => {
      const baseUrl: string = `${process.env.REACT_APP_API}/resources/${resourceId}`;

      const response = await fetch(baseUrl);

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const responseJson = await response.json();

      const loadedResource: ResourceModel = {
        id: responseJson.id,
        title: responseJson.title,
        author: responseJson.author,
        description: responseJson.description,
        shortDescription: responseJson.shortDescription,
        aboutScience: responseJson.aboutScience,
        maxAllowedLaunches: responseJson.maxAllowedLaunches,
        numAvailable: responseJson.numAvailable,
        category: responseJson.category,
        img: responseJson.img,
        lovedBy: responseJson.lovedBy,
        path: responseJson.path,
        trailerPath: responseJson.trailerPath,
        cost: responseJson.cost,
      };

      if (loadedResource.category !== props.category) {
        props.onCategoryChange(loadedResource.category);
      }
      else {
        setResource(loadedResource);
        setIsLoading(false);
      }
    };
    fetchResource().catch((error: any) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
  }, [resourceId]);

  /*
  Reviews are currently not in use

  useEffect(() => {
    const fetchResourceReviews = async () => {
      const reviewUrl: string = `${process.env.REACT_APP_API}/reviews/search/findByResourceId?resourceId=${resourceId}`;
      const responseReviews = await fetch(reviewUrl);

      if (!responseReviews.ok) {
        throw new Error('Something went wrong!')
      }

      const responseJsonReviews = await responseReviews.json();
      const responseData = responseJsonReviews._embedded.reviews;
      const loadedReviews: ReviewModel[] = [];
      let weightedStarReviews: number = 0;

      for (const key in responseData) {
        loadedReviews.push({
          id: responseData[key].id,
          userEmail: responseData[key].userEmail,
          date: responseData[key].date,
          rating: responseData[key].rating,
          resource_id: responseData[key].resourceId,
          reviewDescription: responseData[key].reviewDescription,
        });
        weightedStarReviews = weightedStarReviews + responseData[key].rating;
      }
      if (loadedReviews) {
        const round = (Math.round((weightedStarReviews / loadedReviews.length) * 2) / 2).toFixed(1);
        setTotalStars(Number(round));
      }

      setReviews(loadedReviews);
      setIsLoadingReview(false);

    };

    fetchResourceReviews().catch((error: any) => {
      setIsLoadingReview(false);
      setHttpError(error.message);
    })
  }, [isReviewLeft])

  useEffect(() => {
    const fetchUserReviewResource = async () => {
      if (keycloak && keycloak.authenticated) {
        const url = `${process.env.REACT_APP_API}/reviews/secure/user/resource/${resourceId}`;
        const requestOptions = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            'Content-Type': 'application/json'
          }
        };
        const userReview = await fetch(url, requestOptions);
        if (!userReview.ok) {
          throw new Error('Something went wrong');
        }
        const userReviewResponseJson = await userReview.json();
        setIsReviewLeft(userReviewResponseJson);
      }
      setIsLoadingUserReview(false);
    }
    fetchUserReviewResource().catch((error: any) => {
      setIsLoadingUserReview(false);
      setHttpError(error.message);
    })
  }, [keycloak]);
  */

  const [httpErrorS, setHttpErrorS] = useState(false);
  const [fees, setFees] = useState(0);

  useEffect(() => {
    setFees(resource?.cost ? resource.cost : 2.99);
  }, [keycloak, rentPhase, resource?.cost]);

  useEffect(() => {
    if (rentTag === "rentguest") guestRent();
  }, [rentTag]);

  if (isLoading) {
    return <SpinnerLoading />;
  }

  if (httpErrorS) {
    return (
      <div className="container m-5">
        <p>{httpError}</p>
      </div>
    );
  }

  async function checkoutResource(insecureEmailCheckout: string | null) {
    if ((insecureEmailCheckout != null) && (insecureEmailCheckout.length < 3)) {
      setDisplayError(true);
      throw new Error('insecureEmailCheckout too short');
    }

    let url;
    let requestOptions;

    if (keycloak.authenticated) {
      url = `${process.env.REACT_APP_API}/resources/secure/checkout/resource/${insecureName}/${resource?.id}`;
      requestOptions = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          'Content-Type': 'application/json'
        }
      };
    }
    else {
      url = `${process.env.REACT_APP_API}/resources/insecure/checkout/userInsecure/${insecureEmailCheckout}/${insecureName}/${resource?.id}`;
      requestOptions = {
        method: 'PUT',
        headers: {
          //Authorization: `Bearer ${keycloak.token}`,
          'Content-Type': 'application/json'
        }
      };
    }

    const checkoutResponse = await fetch(url, requestOptions);
    if (!checkoutResponse.ok) {
      setDisplayError(true);
      throw new Error('Something went wrong!:Checkout Resource');
    }
    const checkoutResponseJson = await checkoutResponse.json();

    if (!keycloak.authenticated) {
      setInsecureUserToken(checkoutResponseJson.userToken);
    }
    setDisplayError(false);
    setRentPhase(4);
  }

  /*
  Reviews are currently disabled as we do not have user authentication available yet.

  async function submitReview(starInput: number, reviewDescription: string) {
    let resourceId: number = 0;
    if (resource?.id) {
      resourceId = resource.id;
    }

    const reviewRequestModel = new ReviewRequestModel(starInput, resourceId, reviewDescription);
    const url = `${process.env.REACT_APP_API}/reviews/secure`;
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reviewRequestModel)
    };
    const returnResponse = await fetch(url, requestOptions);
    if (!returnResponse.ok) {
      throw new Error('Something went wrong!');
    }
    setIsReviewLeft(true);
  }
  */

  function guestRent() {
    setDisplayError(false);
    setRentPhase(1);
  }

  return (
    <div className="mb-5">
      {rentPhase === 0 &&
        <ResourceDetailsPage resource={resource} keycloak={keycloak} guestRent={guestRent} displayError={displayError}
          insecureUserToken={insecureUserToken} />
      }
      {rentPhase === 1 &&
        <RentNowPage resource={resource} setRentPhase={setRentPhase} />
      }
      {rentPhase === 2 &&
        <OrderFormPage resource={resource} fees={fees} setInsecureName={setInsecureName}
          insecureName={insecureName} setInsecureEmail={setInsecureEmail} insecureEmail={insecureEmail} setInsecureConfirmEmail={setInsecureConfirmEmail}
          insecureConfirmEmail={insecureConfirmEmail} setRentPhase={setRentPhase}/>
      }
      {rentPhase === 3 &&
        <CreditCardWrapper setHttpErrorS={setHttpErrorS} checkoutResource={checkoutResource}
          fees={fees} insecureEmail={insecureEmail} />
      }
      {rentPhase === 4 &&
        <ThankYouPage resource={resource} insecureUserToken={insecureUserToken} />
      }
    </div>
  );
};
