import React from "react";
import { Link } from "react-router-dom";

import ResourceModel from "../../../models/ResourceModel";

export const ReturnResource: React.FC<{ resource: ResourceModel }> = (props) => {
  return (
    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-3">
      <div className="text-center">
        {props.resource.img &&
          <img src={props.resource.img} width="255" height="155" alt="Resource" />
        }
        <h6 className="mt-2">{props.resource.title}</h6>

        <Link className="btn text-white button-orange rounded-corner" to={`checkout/${props.resource.id}/viewdetails`}>
          RENT
        </Link>
      </div>
    </div>
  );
};
