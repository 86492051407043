import { useEffect, useState } from "react";

import ResourceModel from "../../models/ResourceModel";

import { SearchResource } from "./components/SearchResource";
import { Pagination } from "../Utils/Pagination";
import { SpinnerLoading } from "../Utils/SpinnerLoading";

export const SearchResourcesPage:React.FC<{category: string}> = (props) => {
  const [resources, setResources] = useState<ResourceModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resourcesPerPage] = useState(10);
  const [totalAmountOfResources, setTotalAmountOfResources] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [categorySelection, setCategorySelection] = useState(props.category);
  const [searchUrl, setSearchUrl] = useState(`/search/findByCategory?category=${categorySelection}&page=<pageNumber>&size=${resourcesPerPage}&sort=lovedBy,desc`);

  useEffect(() => {
    const fetchResources = async () => {
      const baseUrl: string = `${process.env.REACT_APP_API}/resources`;
      let url: string = "";

      if (searchUrl === "") {
        url = `${baseUrl}?page=${currentPage - 1}&size=${resourcesPerPage}&sort=lovedBy,desc`;
      } else {
        let searchWithPage = searchUrl.replace('<pageNumber>', `${currentPage - 1}`);
        url = baseUrl + searchWithPage;
      }

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const responseJson = await response.json();
      const responseData = responseJson._embedded.resources;

      setTotalAmountOfResources(responseJson.page.totalElements);
      setTotalPages(responseJson.page.totalPages);

      const loadedResources: ResourceModel[] = [];

      for (const key in responseData) {
        loadedResources.push({
          id: responseData[key].id,
          title: responseData[key].title,
          shortDescription: responseData[key].shortDescription,
          category: responseData[key].category,
          img: responseData[key].img,
          lovedBy: responseData[key].lovedBy,
          trailerPath: responseData[key].trailerPath,
          cost: responseData[key].cost
        });
      }

      setResources(loadedResources);
      setIsLoading(false);
    };
    fetchResources().catch((error: any) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
    window.scrollTo(0, 0);
  }, [currentPage, searchUrl, resourcesPerPage]);

  const searchHandleChange = () => {
    setCurrentPage(1);
    if (search === "") {
      setSearchUrl("");
    } else {
      setSearchUrl(
        `/search/findByCategoryAndTitleContainingIgnoreCase?category=${categorySelection}&title=${search}&page=<pageNumber>&size=${resourcesPerPage}&sort=lovedBy,desc`
      );
    }
  };

  const categoryField = (value: string) => {
    setCurrentPage(1);
    setCategorySelection(value);
    setSearchUrl(`/search/findByCategory?category=${value}&page=<pageNumber>&size=${resourcesPerPage}&sort=lovedBy,desc`);
  }

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  useEffect(() => {
    setIsLoading(true);
    categoryField(props.category);
  }, [props.category]);

  if (isLoading) {
    return <SpinnerLoading />;
  }

  if (httpError) {
    return (
      <div className="container m-5">
        <p>{httpError}</p>
      </div>
    );
  }

  return (
    <div>
      <div className='container'>
        <div>
          <div className='container-fluid py-5 d-flex justify-content-center align-items-center'>
            <div>
              <h1 className='display-5 fw-bold text-title-purple text-center'>Rent any of these games for $2.99 for 7 days</h1>
            </div>
          </div>
          {/*<div className="row">
            <div className="col"></div>
            <div className="d-flex col-8 align-items-center">
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  {categorySelection}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li onClick={() => categoryField('Science')}>
                    <a className="dropdown-item" href="#">
                      Science
                    </a>
                  </li>
                  <li onClick={() => categoryField('Math')}>
                    <a className="dropdown-item" href="#">
                      Math
                    </a>
                  </li>
                </ul>
              </div>
              <input
                className="form-control mx-2"
                type="search"
                placeholder="Search"
                aria-labelledby="Search"
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searchHandleChange()
                  }
                }}
              />
              <button className="btn purple-color text-white" onClick={() => searchHandleChange()}>
                Search
              </button>
            </div>
            <div className="col"></div>
          </div>*/}
          {totalAmountOfResources > 0 ? (
            <div className="d-flex flex-row flex-wrap align-content-stretch">
              {resources.map((resource) => (
                <SearchResource resource={resource} key={resource.id} />
              ))}
            </div>
          ) : (
            <div className="m-5">
              <h3>Sorry, no matches found.</h3>
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center">
            {totalPages > 1 && (
              <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
