import ResourceModel from "../../models/ResourceModel"

export const RentNowPage: React.FC<{ resource: ResourceModel | undefined, setRentPhase: any }> = (props) => {

    return (
        <div className="container justify-content-center align-items-center">
            <div className="text-center container">
                <div className="mt-4">
                    <h2 className="text-title-purple">{props.resource?.title}</h2>
                    {/*<StarsReview rating={totalStars} size={32} />*/}
                    <h5>Rent: ${props.resource?.cost}</h5>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                {props.resource?.img &&
                    <img src={props.resource?.img} alt="Resource" />
                }
            </div>
            <ul>
            </ul>
            <p className="container col-8">
                By clicking the Rent Now button and advancing through the checkout you are renting this game for 7 days with access to a maximum of 150 plays by you and your students. A Wonderville account is not required. For access to the supporting lesson plans or quizzes to this game a paid Wonderville account is required. Refunds are not available for game rentals.
            </p>
            <div className='text-center'>
                <button onClick={() => props.setRentPhase(2)} className='btn btn-lg button-orange text-white rounded-corner mt-3'>Rent Now</button>
            </div>
        </div>
    )
}