import { useEffect, useState } from "react";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
//import { StarsReview } from "../Utils/StarsReview";
import { useKeycloak } from '@react-keycloak/web';
import "../../App.css";
import AddResourceRequest from "../../models/AddResourceRequest";

export const EditResource = () => {

    //const {authState} = useOktaAuth();

    const resourceId = window.location.pathname.split("/")[2];
    const { keycloak } = useKeycloak()

    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(null);

    // New Resource
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [aboutScience, setAboutScience] = useState('');
    const [maxAllowedLaunches, setMaxAllowedLaunches] = useState(0);
    const [category, setCategory] = useState('Category');
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [lovedBy, setLovedBy] = useState(0);
    const [path, setPath] = useState('');
    const [trailerPath, setTrailerPath] = useState('');
    const [displayHeight, setDisplayHeight] = useState(0);
    const [displayWidth, setDisplayWidth] = useState(0);
    const [cost, setCost] = useState(0);

    // Displays
    const [displayWarning, setDisplayWarning] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [editButtonColor, setEditButtonColor] = useState('blue');
    //const [initComplete, setInitComplete] = useState(0);
    //const [displayError, setDisplayError] = useState(false);


    function categoryField(value: string) {
        setCategory(value);
    }

    async function base64ConversionForImages(e: any) {
        if (e.target.files[0]) {
            getBase64(e.target.files[0]);
        }
    }

    function getBase64(file: any) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setSelectedImage(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error', error)
        }
    }




    useEffect(() => {
        const fetchResource = async () => {
            const baseUrl: string = `${process.env.REACT_APP_API}/resources/${resourceId}`;

            const response = await fetch(baseUrl);

            if (!response.ok) {
                throw new Error("Something went wrong!");
            }

            const responseJson = await response.json();

            setTitle(responseJson.title)
            setAuthor(responseJson.author)
            setDescription(responseJson.description)
            setShortDescription(responseJson.shortDescription)
            setAboutScience(responseJson.aboutScience)
            setMaxAllowedLaunches(responseJson.maxAllowedLaunches)
            setCategory(responseJson.category)
            setSelectedImage(responseJson.img)
            setLovedBy(responseJson.lovedBy)
            setPath(responseJson.path)
            setTrailerPath(responseJson.trailerPath)
            setDisplayHeight(responseJson.displayHeight)
            setDisplayWidth(responseJson.displayWidth)
            setCost(responseJson.cost)

            setIsLoading(false);
            setEditButtonColor('blue');
        };
        fetchResource().catch((error: any) => {
            setIsLoading(false);
            setHttpError(error.message);
        });
    }, [resourceId]);


    //useEffect(() => {

    //    setEditButtonColor("red");
        //if (editButtonColor === 'blue' && (initComplete > 1)) setEditButtonColor('red');
        //if (initComplete <= 1) setInitComplete(initComplete + 1);
        //console.log(initComplete);

   // }, [title, author, description, shortDescription, aboutScience, maxAllowedLaunches, category, selectedImage, lovedBy, path, trailerPath, displayHeight, displayWidth, cost]);



    if (isLoading) {
        return <SpinnerLoading />;
    }

    if (httpError) {
        return (
            <div className="container m-5">
                <p>{httpError}</p>
            </div>
        );
    }

    async function editResource() {
        const url = `${process.env.REACT_APP_API}/admin/secure/edit/resource?resourceId=${resourceId}`;
        if (keycloak.authenticated && title !== '' && author !== '' && category !== 'Category' && shortDescription !== '' && trailerPath !== '' && lovedBy >= 0 && description !== '' && maxAllowedLaunches >= 0) {
            const resource: AddResourceRequest = new AddResourceRequest(title, author, shortDescription, description, aboutScience, maxAllowedLaunches, category, lovedBy, path, trailerPath, displayHeight, displayWidth, cost);
            resource.img = selectedImage;
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(resource)
            };
            console.log(requestOptions);
            const submitNewResourceResponse = await fetch(url, requestOptions);
            console.log(submitNewResourceResponse)
            if (!submitNewResourceResponse.ok) {
                throw new Error('Something went wrong!:submitNewResource')
            }
            setDisplayWarning(false);
            setDisplaySuccess(true);
            setEditButtonColor('blue')

        } else {
            setDisplayWarning(true);
            setDisplaySuccess(false);
            setEditButtonColor('red')
        }

    }

    return (
        <div className='container mt-5 mb-5'>
            {displaySuccess &&
                <div className='alert alert-success' role='alert'>
                    Activity edited successfully
                </div>
            }
            {displayWarning &&
                <div className='alert alert-danger' role='alert'>
                    All fields must be filled out
                </div>
            }
            <div className='card'>
                <div className='card-header'>
                    Edit Activity
                </div>
                <div className='card-body'>
                    <form method='POST'>
                        <div className='row'>
                            <div className='col-md-6 mb-3'>
                                <label className='form-label'>Title</label>
                                <input type="text" className='form-control' name='title' required
                                    onChange={e => {setEditButtonColor("red");setTitle(e.target.value)}} value={title} />
                            </div>
                            <div className='col-md-3 mb-3'>
                                <label className='form-label'> Author </label>
                                <input type="text" className='form-control' name='author' required
                                    onChange={e => {setEditButtonColor("red");setAuthor(e.target.value)}} value={author} />
                            </div>
                            <div className='col-md-3 mb-3'>
                                <label className='form-label'> Cost </label>
                                <input type="number" className='form-control' name='cost' required
                                    onChange={e => {setEditButtonColor("red");setCost(Number(e.target.value))}} value={cost} />
                            </div>
                            <div className='col-md-3 mb-3'>
                                <label className='form-label'> Category</label>
                                <button className='form-control btn btn-secondary dropdown-toggle' type='button' 
                                    id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
                                        {category}
                                </button>
                                <ul id='addNewResourceId' className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                                    <li><a onClick={() => categoryField('Science')} className='dropdown-item'>Science</a></li>
                                    <li><a onClick={() => categoryField('Math')} className='dropdown-item'>Math</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='form-label'>Short Description</label>
                            <textarea className='form-control' id='exampleFormControlTextarea2' rows={3}
                                onChange={e => {setEditButtonColor("red");setShortDescription(e.target.value)}} value={shortDescription}></textarea>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='form-label'>Description</label>
                            <textarea className='form-control' id='exampleFormControlTextarea1' rows={3}
                                onChange={e => {setEditButtonColor("red");setDescription(e.target.value)}} value={description}></textarea>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='form-label'>About Science</label>
                            <textarea className='form-control' id='exampleFormControlTextarea1' rows={3}
                                onChange={e => {setEditButtonColor("red");setAboutScience(e.target.value)}} value={aboutScience}></textarea>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='form-label'>Path</label>
                            <textarea className='form-control' id='exampleFormControlTextarea3' rows={1}
                                onChange={e => {setEditButtonColor("red");setPath(e.target.value)}} value={path}></textarea>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='form-label'>Trailer Path</label>
                            <textarea className='form-control' id='exampleFormControlTextarea4' rows={1}
                                onChange={e => {setEditButtonColor("red");setTrailerPath(e.target.value)}} value={trailerPath}></textarea>
                        </div>
                        <div className="row">
                            <div className='col-md-4 mb-3'>
                                <label className='form-label'>Display Height</label>
                                <textarea className='form-control' id='exampleFormControlTextarea4' rows={1}
                                    onChange={e => {setEditButtonColor("red");setDisplayHeight(Number(e.target.value))}} value={displayHeight}></textarea>
                            </div>
                            <div className='col-md-4 mb-3'>
                                <label className='form-label'>Display Width</label>
                                <textarea className='form-control' id='exampleFormControlTextarea4' rows={1}
                                    onChange={e => {setEditButtonColor("red");setDisplayWidth(Number(e.target.value))}} value={displayWidth}></textarea>
                            </div>
                            <div className='col-md-4 mb-3'>
                                <label className='form-label'>Loved By</label>
                                <textarea className='form-control' id='exampleFormControlTextarea5' rows={1}
                                    onChange={e => {setEditButtonColor("red");setLovedBy(Number(e.target.value))}} value={lovedBy}></textarea>
                            </div>
                        </div>
                        
                        <div className='col-md-4 mb-3'>
                            <label className='form-label'>URL Use Limit</label>
                            <input type='number' className='form-control' name='Maximum Allowed Launches' required 
                                onChange={e => {setEditButtonColor("red");setMaxAllowedLaunches(Number(e.target.value))}} value={maxAllowedLaunches}/>
                        </div>
                        
                        <label htmlFor="files" className="btn btn-secondary">Select Image</label>
                        <input id="files" type='file' style={{ visibility: 'hidden' }} onChange={e => {setEditButtonColor("red");base64ConversionForImages(e)}} />
                        <div>
                            &nbsp;
                        </div>
                        <div className='d-none d-lg-block'>
                            {selectedImage &&
                                <img src={selectedImage}
                                    width='255'
                                    height='255'
                                    alt='Resource'
                                />
                            }
                        </div>
                        <div>
                            <button type='button' style={{ backgroundColor: `${editButtonColor}` }} className='btn btn-primary mt-3' onClick={editResource}>
                                Save Edits
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
