export const Pagination: React.FC<{currentPage: number, totalPages: number, paginate: any}> = (props) => {

    const pageNumbers = [];

    for (let i = props.currentPage - 2; i <= props.currentPage + 2; i++) {
        if (i > 0 && i <= props.totalPages) {
            pageNumbers.push(i);
        }
    }

    return (
        <nav aria-label="...">
            <ul className='pagination'>
                <li className='page-item' onClick={() => props.paginate(1)}>
                    <button className='page-link text-purple'>
                        First Page
                    </button>
                </li>
                {pageNumbers.map(number => (
                    <li key={number} onClick={() => props.paginate(number)}
                        className={'page-item ' + (props.currentPage === number ? 'active' : '')}>
                        <button className={'page-link ' + (props.currentPage === number ? 'purple-color' : 'text-purple')}>
                            {number}
                        </button>
                    </li>
                ))}
                <li className='page-item' onClick={() => props.paginate(props.totalPages)}>
                    <button className='page-link text-purple'>
                        Last Page
                    </button>
                </li>
            </ul>
        </nav>
    );
}