import ResourceModel from "../../models/ResourceModel"

export const ResourceDetailsPage: React.FC<{ resource: ResourceModel | undefined, keycloak: any, guestRent: any, displayError: boolean, insecureUserToken: string }> = (props) => {

    return (
        <>
            <div className="container d-block">
                {props.displayError &&
                    <div className='alert alert-danger mt-3' role='alert'>
                        <p>Activity is already rented with same email address.</p>
                    </div>
                }
                <div className="row mt-5">
                    <div className="d-none d-lg-block col-lg-2"></div>
                    <div className="col-12 col-lg-3">
                        <div className="d-flex justify-content-center align-items-center">
                            {props.resource?.img && 
                                <img src={props.resource?.img} alt="Resource" />
                            }
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 container">
                        <div>
                            <h1>{props.resource?.title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: `${props.resource?.description}` }}></div>
                            {/*<StarsReview rating={totalStars} size={32} />*/}
                        </div>
                    </div>
                    <div className="d-none d-lg-block col-lg-2"></div>
                </div>
                <div className="row">
                    <div className="d-flex justify-content-center align-items-center">
                        {!props.keycloak.authenticated &&
                            <button type='button' style={{ width: "225px" }} className='btn btn-md button-orange text-white rounded-corner my-3 mx-2' onClick={props.guestRent}>
                                <h2 className="p-2">RENT NOW</h2>
                            </button>
                        }
                        <a style={{ width: "225px" }} className='btn btn-md button-orange text-white rounded-corner my-3 mx-2' href={`${props.resource?.trailerPath}`} target="_blank" rel="noreferrer">
                            <h2 className="p-2">WATCH TRAILER</h2>
                        </a>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="d-none d-lg-block col-lg-2"></div>
                    <div className="col-12 col-lg-8">
                        <div className="mb-3 d-flex justify-content-center align-items-center">
                            <h2>About the Science</h2>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: `${props.resource?.aboutScience}` }}></div>
                    </div>
                    <div className="d-none d-lg-block col-lg-2"></div>
                </div>
                {/*
                This code is commented out as we currently do not use the reviews feature for the games.
                If we ever implement user authentication, we can look into reintroducing reviews back into the application.

                <CheckoutAndReviewBox resource={resource} mobile={false} currentLoansCount={currentLoansCount} currentLaunchesCount={currentLaunchesCount} isAuthenticated={keycloak.authenticated} isCheckedOut={isCheckedOutDate === "OK_TO_RENT"} checkoutResource={initiateCheckout} isReviewLeft={isReviewLeft} submitReview={submitReview} />
                <hr />
                <div className='col-md-12 mb-3'>
                    {keycloak.authenticated && <button className='btn btn-success btn-lg' onClick={refreshLaunchesNumber}>Refresh Launches Number</button>}
                    <label className='form-label'><a href={`${process.env.REACT_APP_API}/wvAssets/${resource?.id}/token/${userToken}`} target="_blank" rel="noreferrer" onClick={incrementLaunches}><button className='btn btn-success btn-lg' onClick={launchToken}>Launch Token</button></a>&nbsp;</label>
                    <textarea className='form-control' id='exampleFormControlTextarea2' rows={1}
                        onChange={e => setUserToken(e.target.value)} value={userToken}></textarea>
                </div>
                <hr />
                <LatestReviews reviews={reviews} resourceId={resource?.id} mobile={false} />
                */}
            </div>
        </>
    )
}