import { Link } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';

import ResourceModel from "../../../models/ResourceModel";

export const SearchResource: React.FC<{ resource: ResourceModel }> = (props) => {
    const { keycloak } = useKeycloak();

    const isAdmin = (keycloak: any) => {
        return keycloak &&
            keycloak.tokenParsed &&
            keycloak.tokenParsed.resource_access['wonderplay-app'] &&
            keycloak.tokenParsed.resource_access['wonderplay-app'].roles.includes('wvAdmin');
    }

    const format_loved_by = (lovedBy?: number) => {
        let value = "";

        if (lovedBy === undefined || lovedBy === 0) {
            return "NEW!";
        }
        else if (lovedBy >= 1000) {
            value = String(lovedBy / 1000) + "M";
        }
        else {
            value = String(lovedBy) + "K";
        }

        return "Loved by " + value + "+ players";
    }

    return (
        <div className='d-flex flex-column g-0 p-4 col-md-6'>
            <div className='row m-1'>
                <div className='d-flex justify-content-center align-items-center'>
                    <Link className="text-purple" to={`/checkout/${props.resource.id}/viewdetails`}>
                        {props.resource.img &&
                            <img src={props.resource.img} width="255" height="155" alt='Resource' />
                        }
                    </Link>
                </div>
                <div className='mt-3 p-1'>
                    <h3>
                        <Link className="text-purple" to={`/checkout/${props.resource.id}/viewdetails`}>
                            {props.resource.title}
                        </Link>
                    </h3>
                </div>
                <p className="mb-3 p-1">
                    {props.resource.shortDescription}
                </p>
            </div>
            <div className="row flex-grow-1"></div>
            <div className='row m-1'>
                <div className='col-md-12'>
                    <div className="flex-grow-1"></div>
                    <p className="mt-3 mb-3 d-flex justify-content-center align-items-center">
                        <a className='btn btn-md button-orange text-white rounded-corner' href={`${props.resource.trailerPath}`} target="_blank" rel="noreferrer">
                            <h2 className="p-2">WATCH TRAILER</h2>
                        </a>
                    </p>
                    <div className="d-flex justify-content-center align-items-center">
                        <hr className="col-md-3" />
                        <h3 className="m-2 col-md-6 text-center text-purple">
                            {format_loved_by(props.resource.lovedBy)}
                        </h3>
                        <hr className="col-md-3" />
                    </div>
                    <div className="mb-3 d-flex justify-content-center align-items-center">
                        <h3 className="text-purple">${props.resource.cost} for 7 days</h3>
                    </div>
                </div>
                {!keycloak.authenticated &&
                    <div className='mt-1 mb-3 col-md-12 d-flex justify-content-center align-items-center'>
                        <Link className='btn btn-md button-orange text-white rounded-corner' to={`/checkout/${props.resource.id}/rentguest`}>
                            <h2 className="p-2">RENT NOW</h2>
                        </Link>
                    </div>
                }
                {isAdmin(keycloak) &&
                    <div className='m-1 col-md-12 d-flex justify-content-center align-items-center'>
                        <Link className='btn btn-md main-color text-white' to={`/editresource/${props.resource.id}`}>
                            Edit
                        </Link>
                    </div>
                }
                <div className="d-flex justify-content-center align-items-center">
                    <hr className="mb-1 col" />
                </div>
            </div>
        </div>
    );
}