import { Redirect, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Navbar } from "../NavbarAndFooter/Navbar";
import { useState } from "react";
import { HomePage } from "../HomePage/HomePage";
import { SearchResourcesPage } from "../SearchResourcesPage/SearchResourcesPage";
import { ResourceCheckoutPage } from "../ResourceCheckoutPage/ResourceCheckoutPage";
import { LaunchError } from "../Utils/LaunchError";
import { ResourcePage } from "../ResourcePage/ResourcePage";
import PrivateRoute from "../../lib/PrivateRoute";
import { EditResource } from "../EditResource/EditResource";
import { MessagesPage } from "../MessagesPage/MessagesPage";
import { ManageWonderplayPage } from "../ManageWonderplayPage/ManageWonderplayPage";
import { Footer } from "../NavbarAndFooter/Footer";

export const RoutingPage = () => {
    const [category, setCategory] = useState("");

    const onCategoryChange = (newCategory: string) => {
        setCategory(newCategory);
    }

    return (
        <Router>
            <div className="d-flex flex-column min-vh-100">
                <Navbar category={category} onCategoryChange={onCategoryChange} />
                <div className="flex-grow-1">
                    <Switch>
                        <Route path="/" exact>
                            <Redirect to="home" />
                        </Route>
                        <Route path="/home" exact component={HomePage} />
                        {/*<Route path="/search" component={SearchResourcesPage} />*/}
                        <Route path="/science" render={() => <SearchResourcesPage category={"Science"} />} />
                        <Route path="/math" render={() => <SearchResourcesPage category={"Math"} />} />
                        {/*
              This ReviewListPage is a leftover from the library app and is not currently used in  WonderPlay.
              If we eventually add authentication, reviews could be added back as a feature to be displayed
              on the game pages.
              
              <Route path="/reviewlist/:resourceId" component={ReviewListPage} />
              */}
                        <Route path="/checkout/:resourceId/:rentTag" render={() => <ResourceCheckoutPage category={category} onCategoryChange={onCategoryChange} />} />
                        <Route path="/launcherror" component={LaunchError} />
                        <Route path="/resource/:resourceId/:token" render={() => <ResourcePage category={category} onCategoryChange={onCategoryChange} />} />
                        <PrivateRoute path="/editresource/:resourceId" component={EditResource} />
                        {/*
              This ShelfPage is a leftover from the library app and is not currently used in WonderPlay.
              If we eventually add authentication, it could be used to display the games that a user currently
              has rented, and give easy access to their links.

              <PrivateRoute path="/shelf" component={ShelfPage} />
              */}
                        {/* 
              The MessagesPage is a leftover from the library app and has currently no use in WonderPlay.
              As it is still accessible from the navbar if you're logged in as admin, I have not commented out
              the route to it, but it should be hidden eventually.
              */}
                        <PrivateRoute path="/messages" component={MessagesPage} />
                        <PrivateRoute path="/admin" component={ManageWonderplayPage} />
                        {/*
              This PaymentPage is a leftover from the library app and is not currently used in WonderPlay.
              As we currently charge upfront on a user renting a game instead of charging late fees, I don't
              see a use for this page in the future for WonderPlay and we could probably safely remove it
              entirely.
              
              <PrivateRoute path="/fees" component={PaymentPage} />
              */}
                        <Redirect to="/home" />
                    </Switch>
                </div>
                <Footer />
            </div>
        </Router>
    )
}