//import { useOktaAuth } from "@okta/okta-react";
import ResourceModel from "../../../models/ResourceModel";
import { useKeycloak } from '@react-keycloak/web';

export const ChangeQuantityOfResource: React.FC<{ resource: ResourceModel, deleteResource:any }> = (props, key) => {
    
    //const {authState} = useOktaAuth();
    const { keycloak } = useKeycloak();

    async function deleteResource(){
        const url = `${process.env.REACT_APP_API}/admin/secure/delete/resource?resourceId=${props.resource.id}`;
        const requestOptions = {
            method: 'DELETE',
            headers:{
                Authorization: `Bearer ${keycloak.token}`,
                'Content-Type': 'application/json'
            } 
        };
        const updateResponse = await fetch(url, requestOptions);
        if(!updateResponse.ok){
            throw new Error('Something went wrong!:DELETE');
        }
        props.deleteResource();    
    }

    return (
        <div className='card mt-3 shadow p-3 mb-3 bg-body rounded'>
            <div className='row g-0'>
                <div className='col-md-3'>
                    <div className='d-none d-lg-block'>
                        {props.resource.img ?
                            <img src={props.resource.img} width='255' height='255' alt='Resource' />
                            :
                            <img src={require('./../../../Images/ResourcesImages/resource-mindfuel-1000.png')} 
                                width='123' height='196' alt='Resource' />
                        }
                    </div>
                    <div className='d-lg-none d-flex justify-content-center align-items-center'>
                        {props.resource.img ?
                            <img src={props.resource.img} width='255' height='255' alt='Resource' />
                            :
                            <img src={require('./../../../Images/ResourcesImages/resource-mindfuel-1000.png')} 
                                width='123' height='196' alt='Resource' />
                        }
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='card-body'>
                        <h5 className='card-title'>{props.resource.author}</h5>
                        <h4>{props.resource.title}</h4>
                        <p className='card-text'> {props.resource.description} </p>
                    </div>
                </div>
{/*                 <div className='mt-3 col-md-3'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <p>Total Quantity: <b>{quantity}</b></p>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <p>Resources Remaining: <b>{remaining}</b></p>
                    </div>
                </div> */}
                <div className='mt-3 col-md-1'>
                    <div className='d-flex justify-content-start'>
                        <button className='m-1 btn btn-lg btn-danger' onClick={deleteResource}>Delete</button>
                    </div>
                </div>
{/*                 <button className='m1 btn btn-md main-color text-white' onClick={increaseQuantity}>Add Quantity</button>
                <button className='m1 btn btn-md btn-warning' onClick={decreaseQuantity}>Decrease Quantity</button> */}
            </div>
        </div>
    );
}