import { Carousel } from "./components/Carousel";
import { ExploreTopResources } from "./components/ExploreTopResources";

export const HomePage = () => {
  return (
    <>
      <ExploreTopResources />
      <Carousel />
    </>
  );
}