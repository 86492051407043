import * as React from 'react';
import { useKeycloak } from '@react-keycloak/web'
import LoginPage from './LoginPage';
import {
    Route,
    //Redirect,
    RouteProps
} from 'react-router-dom';


interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
    //isSignedIn: boolean;
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, ...rest } = props;
    const { keycloak } = useKeycloak()
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                keycloak?.authenticated ? (
                    <Component {...routeProps} />
                ) : (<LoginPage />
                    /*
                    <Redirect
                        to={{
                            pathname: '/home',
                            state: { from: routeProps.location }
                        }}
                    />
                    */
                )
            }
        />
    );
};

export default PrivateRoute;