import { useState } from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { useKeycloak } from "@react-keycloak/web";

export const CreditCardPage: React.FC<{ setHttpErrorS: any, checkoutResource: any, fees: number, insecureEmail: string }> = (props) => {

  const { keycloak } = useKeycloak()

  const elements = useElements();
  const stripe = useStripe();

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function paymentCheckout() {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required'
    });

    if (result.error) {
      if (result.error.message !== undefined) {
        setDisplayError(true);
        setErrorMessage(result.error.message);
      }
      console.log(result.error.message);
    }
    else {
      let url = ""
      let requestOptions: any
      if (keycloak.authenticated) {
        url = `${process.env.REACT_APP_API}/payment/secure/payment-complete`;
        requestOptions = {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            'Content-Type': 'application/json'
          }
        };
      }
      else {
        url = `${process.env.REACT_APP_API}/payment/insecure/payment-complete/userInsecure/${props.insecureEmail}`;
        requestOptions = {
          method: 'PUT',
          headers: {
            //Authorization: `Bearer ${keycloak.token}`,
            'Content-Type': 'application/json'
          }
        };
      }
      const stripeResponse = await fetch(url, requestOptions);
      if (!stripeResponse.ok) {
        props.setHttpErrorS(true)
        setSubmitDisabled(false)
        throw new Error('Something went wrong! paymentcomplete')
      }
      const stripeResponseJson = await stripeResponse.json();
      console.log(stripeResponseJson);

      if (keycloak.authenticated) {
        props.checkoutResource();
      }
      else {
        props.checkoutResource(stripeResponseJson.result);
      }
      setSubmitDisabled(false);
    }
    props.setHttpErrorS(false);
  }

  return (
    <div className="mb-5">
      <div className='container'>
        {displayError &&
          <div className='alert alert-danger mt-3' role='alert'>
            <p>{errorMessage}</p>
          </div>
        }
        {props.fees > 0 &&
          <div className='card mt-3'>
            <h5 className='card-header'>Amount pending: <span className='text-danger'>${props.fees}</span></h5>
            <div className='card-body'>
              <PaymentElement id='card-element' />
              {/*keyloak.authenticated?
                <button disabled={submitDisabled} type='button' className='btn btn-md main-color text-white mt-3' 
                    onClick={checkout}>
                    Pay fees
                </button>
                :
                <button disabled={submitDisabled} type='button' className='btn btn-md main-color text-white mt-3' 
                    onClick={insecureCheckout}>
                    Pay fees
                </button>*/}
              <button disabled={submitDisabled} type='button' className='btn btn-md button-orange text-white mt-3'
                onClick={paymentCheckout}>
                Complete Payment
              </button>
            </div>
          </div>
        }
        {submitDisabled && <SpinnerLoading />}
      </div>
    </div>
  )
}