import ResourceModel from "../../models/ResourceModel"

export const ThankYouPage: React.FC<{ resource: ResourceModel | undefined, insecureUserToken: string }> = (props) => {

    return (
        <div className="container justify-content-center align-items-center">
            <div className="text-center container">
                <h2 className="mt-3 text-title-purple">Thank you for renting {props.resource?.title}</h2>
                <div className="d-flex justify-content-center align-items-center">
                    {props.resource?.img &&
                        <img src={props.resource?.img} alt="Resource" />
                    }
                </div>
                <h3 className="mb-1">This is your rental link:</h3>
                <a href={`${process.env.REACT_APP_BASE}/resource/${props.resource?.id}/${props.insecureUserToken}`} target="_blank" rel="noreferrer">
                    {`${process.env.REACT_APP_BASE}/resource/${props.resource?.id}/${props.insecureUserToken}`}
                </a>
                <button className='btnctoc' onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_BASE}/resource/${props.resource?.id}/${props.insecureUserToken}`)}>
                    <img src={require('./../../Images/PublicImages/cc_sm.png')} width='30' height='30' alt='Copy' />
                </button>
            </div>
        </div>
    )
}