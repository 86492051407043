import { useKeycloak } from '@react-keycloak/web';
import { useState } from 'react';

import { NavLink } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

export const Navbar:React.FC<{category: string, onCategoryChange: any}> = (props) => {

  const { keycloak } = useKeycloak()
  const history = useHistory();
  const location = useLocation();

  const handleLogInOut = () => {
    if (keycloak.authenticated) {
      history.push("/");
      keycloak.logout()
    } else {
      keycloak.login()
    }
  }

  const getLogInOutText = () => {
    return keycloak.authenticated ? "Logout" : "Login"
  }

  const isAdmin = (keycloak: any) => {
    return keycloak &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.resource_access['wonderplay-app'] &&
      keycloak.tokenParsed.resource_access['wonderplay-app'].roles.includes('wvAdmin')
  }

  const category = () => {
    if (location.pathname.split("/")[1].toLowerCase() === "home" || location.pathname.split("/")[1].toLowerCase() === "science" && props.category !== "Science") {
      props.onCategoryChange("Science");
    }
    else if (location.pathname.split("/")[1].toLowerCase() === "math" && props.category !== "Math") {
      props.onCategoryChange("Math");
    }
    return props.category;
  }

  const oppositeCategory = () => {
    if (props.category === "Science") {
      return "Math";
    }
    else {
      return "Science";
    }
  }

  return (
    <nav className='navbar navbar-expand-lg navbar-dark main-color py-3 purple-background'>
      <div className='d-inline container-fluid'>
        <h1 className='display-1 m-0 fw-bold text-center text-white'>
          WonderPlay
        </h1>
        <h5 className='display-5 m-0 fw-bold text-center text-white'>
          {category()}
        </h5>
        <p className='display-6 text-center text-white'>
          Our best games to rent with NO commitment
        </p>
        <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarNavDropdown' aria-controls='navbarNavDropdown' aria-expanded='false' aria-label='Toggle Navigation'>
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNavDropdown'>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/home' onClick={() => props.onCategoryChange("Science")}>Home</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to={'/' + props.category.toLowerCase()}>Games</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to={'/' + oppositeCategory().toLowerCase()} onClick={() => props.onCategoryChange(oppositeCategory())}>Go to {oppositeCategory()}</NavLink>
            </li>
            {/*
            This ShelfPage is a leftover from the library app and is not currently used in WonderPlay.
            If we eventually add authentication, it could be used to display the games that a user currently
            has rented, and give easy access to their links.

            {keycloak.authenticated && 
            <li className='nav-item'>
              <NavLink className='nav-link' to='/shelf'>Shelf</NavLink>
            </li>      
            */}
            {/*
            This PaymentPage is a leftover from the library app and is not currently used in WonderPlay.
            As we currently charge upfront on a user renting a game instead of charging late fees, I don't
            see a use for this page in the future for WonderPlay and we could probably safely remove it
            entirely.

            keycloak.authenticated && 
            <li className='nav-item'>
              <NavLink className='nav-link' to='/fees'>Pay Fees</NavLink>
            </li>      
            */}
            {keycloak.authenticated && isAdmin(keycloak) &&
              <>
                <li className='nav-item'>
                  <NavLink className='nav-link' to='/admin'>Admin</NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink type='button' className='btn btn-outline-light' exact to='/login' onClick={handleLogInOut}>{getLogInOutText()}</NavLink>
                </li>
              </>
            }
          </ul>
          {/*
          The login button has been hidden as we currently do not want to support user accounts.

          <ul className='navbar-nav ms-auto'>
            <li className='nav-item m-1'>
              <NavLink type='button' className='btn btn-outline-light' exact to='/login' onClick={handleLogInOut}>{getLogInOutText()}</NavLink>
            </li>
          </ul>
          */}
        </div>
      </div>
    </nav>
  );
}