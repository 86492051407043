import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ResourceModel from "../../../models/ResourceModel";

import { ReturnResource } from "./ReturnResource";
import { SpinnerLoading } from "../../Utils/SpinnerLoading";


export const Carousel = () => {
    const [resources, setResources] = useState<ResourceModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(null);

    useEffect(() => {
        const fetchResources = async () => {
            const baseUrl: string = `${process.env.REACT_APP_API}/resources`;
            const url: string = `${baseUrl}?page=0&size=9&sort=lovedBy,desc`;
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error('Something went wrong');
            }

            const responseJson = await response.json();
            const responseData = responseJson._embedded.resources;
            const loadedResources: ResourceModel[] = [];

            for (const key in responseData) {
                loadedResources.push({
                    id: responseData[key].id,
                    title: responseData[key].title,
                    img: responseData[key].img
                })
            }

            setResources(loadedResources);
            setIsLoading(false);
        }

        fetchResources().catch((error: any) => {
            setIsLoading(false);
            setHttpError(error.message)
        })
    }, []);

    if (isLoading) {
        return (
            <SpinnerLoading />
        )
    }

    if (httpError) {
        return (
            <div className='container m-5'>
                <p>{httpError}</p>
            </div>
        )
    }

    return (
        <div className='container mt-3 mb-5'>
            <div className='homepage-carousel-title'>
                <h3>Check out our top games:</h3>
            </div>
            <div id='carouselExampleControls' className='carousel carousel-dark slide mt-4' data-bs-interval='false'>
                <div className='carousel-inner'>
                    <div className='carousel-item active'>
                        <div className='row d-none d-lg-flex justify-content-center align-items-center'>
                            {resources.slice(0, 3).map(resource => (
                                <ReturnResource resource={resource} key={resource.id} />
                            ))}
                        </div>
                        <div className='row d-lg-none d-flex justify-content-center align-items-center'>
                            <ReturnResource resource={resources[0]} key={resources[0].id} />
                        </div>
                    </div>
                    <div className='carousel-item'>
                        <div className='row d-none d-lg-flex justify-content-center align-items-center'>
                            {resources.slice(3, 6).map(resource => (
                                <ReturnResource resource={resource} key={resource.id} />
                            ))}
                        </div>
                        <div className='row d-lg-none d-flex justify-content-center align-items-center'>
                            <ReturnResource resource={resources[1]} key={resources[1].id} />
                        </div>
                    </div>
                    <div className='carousel-item'>
                        <div className='row d-none d-lg-flex justify-content-center align-items-center'>
                            {resources.slice(6, 9).map(resource => (
                                <ReturnResource resource={resource} key={resource.id} />
                            ))}
                        </div>
                        <div className='row d-lg-none d-flex justify-content-center align-items-center'>
                            <ReturnResource resource={resources[2]} key={resources[2].id} />
                        </div>
                    </div>
                    <button className='carousel-control-prev' type='button'
                        data-bs-target='#carouselExampleControls' data-bs-slide='prev'>
                        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                        <span className='visually-hidden'>Previous</span>
                    </button>
                    <button className='carousel-control-next' type='button'
                        data-bs-target='#carouselExampleControls' data-bs-slide='next'>
                        <span className='carousel-control-next-icon' aria-hidden='true'></span>
                        <span className='visually-hidden'>Next</span>
                    </button>
                </div>
            </div>
            <div className='homepage-carousel-title mt-3'>
                <Link className='btn btn-lg button-orange text-white' to='/science'><h1>View All Games</h1></Link>
            </div>
        </div>
    );
}