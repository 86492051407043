class AddResourceRequest {
    title:string;
    author: string;
    shortDescription:string;
    description: string;
    aboutScience: string;
    maxAllowedLaunches: number;
    category: string;
    img?: string;
    lovedBy: number;
    path: string;
    trailerPath: string;
    displayHeight: number;
    displayWidth: number;
    cost: number;

    constructor(title:string, author: string, shortDescription:string, description: string, aboutScience: string, maxAllowedLaunches: number, category:string,
        lovedBy:number, path:string, trailerPath:string, displayHeight: number, displayWidth: number, cost:number){
        this.title = title;
        this.author = author;
        this.shortDescription = shortDescription;
        this.description = description;
        this.aboutScience = aboutScience;
        this.maxAllowedLaunches = maxAllowedLaunches;
        this.category = category;
        this.lovedBy = lovedBy;
        this.path = path;
        this.trailerPath=trailerPath;
        this.displayHeight = displayHeight;
        this.displayWidth = displayWidth;
        this.cost = cost; 
    }

}

export default AddResourceRequest;