//import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import AddResourceRequest from '../../../models/AddResourceRequest';
import { useKeycloak } from '@react-keycloak/web';

export const AddNewResource = () => {

    //const { authState } = useOktaAuth();
    const { keycloak } = useKeycloak()

    // New Resource
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [aboutScience, setAboutScience] = useState('');
    const [maxAllowedLaunches, setMaxAllowedLaunches] = useState(0);
    const [category, setCategory] = useState('Category');
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [lovedBy, setLovedBy]= useState(0);
    const [path, setPath] = useState('');
    const [trailerPath, setTrailerPath] = useState('');
    const [displayHeight, setDisplayHeight] = useState(0);
    const [displayWidth, setDisplayWidth] = useState(0);
    const [cost, setCost] = useState(0);

    // Displays
    const [displayWarning, setDisplayWarning] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);

    function categoryField(value: string) {
        setCategory(value);
    }

    async function base64ConversionForImages (e: any){
        if(e.target.files[0]){
            getBase64(e.target.files[0]);
        }
    }

    function getBase64(file: any){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(){
            setSelectedImage(reader.result);
        };
        reader.onerror = function (error){
            console.log('Error', error)
        }
    }

    async function submitNewResource(){
        const url = `${process.env.REACT_APP_API}/admin/secure/add/resource`;
        if (keycloak.authenticated && title !=='' && author !=='' && category !=='Category'&& shortDescription !==''&& trailerPath !==''&& lovedBy >= 0 && description !=='' && maxAllowedLaunches >=0){
            const resource: AddResourceRequest = new AddResourceRequest(title, author, shortDescription, description, aboutScience, maxAllowedLaunches, category, lovedBy, path, trailerPath, displayHeight, displayWidth, cost);
            resource.img = selectedImage;
            const requestOptions = {
                method:'POST',
                headers:{
                    Authorization:`Bearer ${keycloak.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(resource)
            };
            const submitNewResourceResponse = await fetch(url, requestOptions);
            if(!submitNewResourceResponse.ok){
                throw new Error ('Something went wrong!:submitNewResource')
            }
            setTitle('');
            setAuthor('');
            setShortDescription('');
            setDescription('');
            setAboutScience('');
            setMaxAllowedLaunches(0);
            setCategory('Category');
            setSelectedImage(null);
            setLovedBy(0);
            setPath('');
            setTrailerPath('');
            setDisplayHeight(0);
            setDisplayWidth(0);
            setCost(0);
            setDisplayWarning(false);
            setDisplaySuccess(true);

        } else {
            setDisplayWarning(true);
            setDisplaySuccess(false);
        }

    }

    return (
        <div className='container mt-5 mb-5'>
            {displaySuccess && 
                <div className='alert alert-success' role='alert'>
                    Resource added successfully
                </div>
            }
            {displayWarning && 
                <div className='alert alert-danger' role='alert'>
                    All fields must be filled out
                </div>
            }
            <div className='card'>
                <div className='card-header'>
                    Add a new activity
                </div>
                <div className='card-body'>
                    <form method='POST'>
                        <div className='row'>
                            <div className='col-md-6 mb-3'>
                                <label className='form-label'>Title</label>
                                <input type="text" className='form-control' name='title' required 
                                    onChange={e => setTitle(e.target.value)} value={title} />
                            </div>
                            <div className='col-md-3 mb-3'>
                                <label className='form-label'> Author </label>
                                <input type="text" className='form-control' name='author' required 
                                    onChange={e => setAuthor(e.target.value)} value={author}/>
                            </div>
                            <div className='col-md-3 mb-3'>
                                <label className='form-label'> Cost </label>
                                <input type="number" className='form-control' name='cost' required 
                                    onChange={e => setCost(Number(e.target.value))} value={cost}/>
                            </div>
                            <div className='col-md-3 mb-3'>
                                <label className='form-label'> Category</label>
                                <button className='form-control btn btn-secondary dropdown-toggle' type='button' 
                                    id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
                                        {category}
                                </button>
                                <ul id='addNewResourceId' className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                                    <li><a onClick={() => categoryField('Science')} className='dropdown-item'>Science</a></li>
                                    <li><a onClick={() => categoryField('Math')} className='dropdown-item'>Math</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='form-label'>Short Description</label>
                            <textarea className='form-control' id='exampleFormControlTextarea2' rows={3} 
                                onChange={e => setShortDescription(e.target.value)} value={shortDescription}></textarea>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='form-label'>Description</label>
                            <textarea className='form-control' id='exampleFormControlTextarea1' rows={3} 
                                onChange={e => setDescription(e.target.value)} value={description}></textarea>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='form-label'>About Science</label>
                            <textarea className='form-control' id='exampleFormControlTextarea1' rows={3} 
                                onChange={e => setAboutScience(e.target.value)} value={aboutScience}></textarea>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='form-label'>Path</label>
                            <textarea className='form-control' id='exampleFormControlTextarea3' rows={1} 
                                onChange={e => setPath(e.target.value)} value={path}></textarea>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='form-label'>Trailer Path</label>
                            <textarea className='form-control' id='exampleFormControlTextarea4' rows={1} 
                                onChange={e => setTrailerPath(e.target.value)} value={trailerPath}></textarea>
                        </div>
                        <div className='row'>
                            <div className='col-md-4 mb-3'>
                                <label className='form-label'>Display Height</label>
                                <textarea className='form-control' id='exampleFormControlTextarea4' rows={1} 
                                    onChange={e => setDisplayHeight(Number(e.target.value))} value={displayHeight}></textarea>
                            </div>
                            <div className='col-md-4 mb-3'>
                                <label className='form-label'>Display Width</label>
                                <textarea className='form-control' id='exampleFormControlTextarea4' rows={1} 
                                    onChange={e => setDisplayWidth(Number(e.target.value))} value={displayWidth}></textarea>
                            </div>
                            <div className='col-md-4 mb-3'>
                                <label className='form-label'>Loved By</label>
                                <textarea className='form-control' id='exampleFormControlTextarea5' rows={1} 
                                    onChange={e => setLovedBy(Number(e.target.value))} value={lovedBy}></textarea>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label className='form-label'>URL Use Limit</label>
                            <input type='number' className='form-control' name='Maximum Allowed Launches' required 
                                onChange={e => setMaxAllowedLaunches(Number(e.target.value))} value={maxAllowedLaunches}/>
                        </div>
                        <input type='file' onChange={e => base64ConversionForImages(e)}/>
                        <div>
                            <button type='button' className='btn btn-primary mt-3' onClick={submitNewResource}>
                                Add Activity
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}