import "./App.css";

import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import PrivateRoute from "./lib/PrivateRoute";
import { Dimmer, Header, Icon } from 'semantic-ui-react';
import { Redirect, Route, Switch, BrowserRouter as Router } from "react-router-dom";

import { ResourceCheckoutPage } from "./layouts/ResourceCheckoutPage/ResourceCheckoutPage";
import { HomePage } from "./layouts/HomePage/HomePage";
import { Footer } from "./layouts/NavbarAndFooter/Footer";
import { Navbar } from "./layouts/NavbarAndFooter/Navbar";
import { SearchResourcesPage } from "./layouts/SearchResourcesPage/SearchResourcesPage";
//import { ReviewListPage } from "./layouts/ResourceurceurceCheckoutPage/ReviewListPage/ReviewListPage";
import { MessagesPage } from "./layouts/MessagesPage/MessagesPage";
import { ManageWonderplayPage } from "./layouts/ManageWonderplayPage/ManageWonderplayPage";
import { EditResource } from "./layouts/EditResource/EditResource";
import { LaunchError } from "./layouts/Utils/LaunchError";
import { ResourcePage } from "./layouts/ResourcePage/ResourcePage";
import { useState } from "react";
import { MathResourcesPage } from "./layouts/SearchResourcesPage/MathResourcesPage";
import { RoutingPage } from "./layouts/RoutingPage/RoutingPage";
//import { ShelfPage } from "./layouts/ShelfPage/ShelfPage";
//import { PaymentPage } from "./layouts/PaymentPage/PaymentPage";

export const App = () => {
  const keycloakURL: string = `${process.env.REACT_APP_KEYCLOAK_BASE_URL}`;

  /*
  Logging of the Keycloak auth URL for debugging purposes, commented out so that
  it is not visible to the end user on the console.

  console.log("Keycloak URL: " + keycloakURL);
  */

  const keycloak: any = new Keycloak({
    url: keycloakURL,
    realm: "wonderplay-services",
    clientId: "wonderplay-app"
  })
  const initOptions = { pkceMethod: 'S256' }

  /*
  Does this function even do anything?
  */
  const handleOnEvent = async (event: any, error: any) => {
    if (event === 'onAuthSuccess') {
      if (keycloak.authenticated) {
        let response = { "status": 200, "data": { "name": "Capac", "avatar": "img" } }
        /*
        Logging of JWT for debugging purposes, commented out so that it is not visible
        to the end user on the console, as it could be a potential security breach.

        console.log(keycloak.token);
        */

        /*
        This if statement will never be triggered as response.status is defined as 200

        if (response.status === 404) {
          const userExtra = { avatar: keycloak.tokenParsed?.preferred_username }
          console.log('UserExtra created for ' + keycloak.tokenParsed?.preferred_username)
        }
        */
        keycloak['avatar'] = response.data.avatar
      }
    }
  }

  const loadingComponent = (
    <Dimmer inverted active={true} page>
      <Header style={{ color: '#4d4d4d' }} as='h2' icon inverted>
        <Icon loading name='cog' />
        {/*<Header.Content>Keycloak is loading
          <Header.Subheader style={{ color: '#4d4d4d' }}>or running authorization code flow with PKCE</Header.Subheader>
        </Header.Content>*/}
      </Header>
    </Dimmer>
  )

  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions} LoadingComponent={loadingComponent} onEvent={(event, error) => handleOnEvent(event, error)}>
      <RoutingPage/>
    </ReactKeycloakProvider>
  )
};

export default App;
