import * as React from 'react';
import { useKeycloak } from '@react-keycloak/web'

const LoginPage = () => {
const { keycloak } = useKeycloak()
keycloak.login()

return(<></>);

}

export default LoginPage;

